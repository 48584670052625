import React from "react";
import { Button, Upload } from "antd";
import type { UploadFile, UploadProps } from "antd";
import ImgCrop from "antd-img-crop";
import { UploadOutlined } from "@ant-design/icons";

import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";
import { DEVICES } from "../DropZone/v2/Data";
import "./style.scss";

// Define your props here
interface UploadMultipleFileProps {
  className?: string;
  deviceSelected?: string;
  onSubmit?: (params: any) => void;
  loading: boolean;
  fileList: UploadFile[];
  onChange: (params: any) => void;
}
const MAX_FILES_UPLOAD = 4;
const MIN_FILES_UPLOAD = 1;

const TUploadMultipleFile = (props: UploadMultipleFileProps) => {
  const isEnableUpload = props.fileList.length >= MIN_FILES_UPLOAD;

  // const reqFile = RequirementsFile_v2(props.deviceSelected);

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    props.onChange(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as Blob);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const checkFileSize = (file) => {
    const maxSizeInBytes = 1024 * 1024; // 5 MB
    if (file.size > maxSizeInBytes) {
      // message.error("El archivo excede el tamaño máximo permitido (5 MB)");
      return false || Upload.LIST_IGNORE; // Cancela la carga del archivo
    }
    return true; // Permite la carga del archivo
  };

  const mockUpload = async (options) => {
    await new Promise((resolve) => setTimeout(resolve, 500));

    options.onSuccess({status: 'success' }, options.file);
    // message.success(`${options.file.name} subido correctamente`);
  }

  return (
    <div className={`UploadMultipleFile ${props.className}`}>
      {props.deviceSelected === DEVICES.image && (
        <ImgCrop rotationSlider>
          <Upload
            // action="https://webhook.site/11502a40-7519-480d-9906-35205f8b00c8"
            // beforeUpload={checkFileSize}
            customRequest={mockUpload}
            listType="picture-card"
            fileList={props?.fileList}
            onChange={onChange}
            onPreview={onPreview}
            accept="image/jpeg,image/png,image/jpg"
          >
            {props.fileList.length < MAX_FILES_UPLOAD && (
              <ReactSVG src={pathServer.PATH_ICONS + "ic_circle.svg"} />
            )}
          </Upload>
        </ImgCrop>
      )}

      {props.deviceSelected === DEVICES.video && (
        <Upload
          listType="picture-card"
          fileList={props.fileList}
          onChange={onChange}
          accept="video/mp4,video/mov"
          // action="https://webhook.site/11502a40-7519-480d-9906-35205f8b00c8"
          customRequest={mockUpload}
        >
          {props.fileList.length < MAX_FILES_UPLOAD && (
            <ReactSVG src={pathServer.PATH_ICONS + "ic_circle.svg"} />
          )}
        </Upload>
      )}

      {props.deviceSelected === DEVICES.audio && (
        <Upload
          listType="picture-card"
          fileList={props.fileList}
          onChange={onChange}
          accept="audio/wav,audio/mp3,audio/wma,audio/flac"
        >
          {props.fileList.length < MAX_FILES_UPLOAD && (
            <ReactSVG src={pathServer.PATH_ICONS + "ic_circle.svg"} />
          )}
        </Upload>
      )}

      {isEnableUpload && (
        <Button
          className="d-block mt-1"
          loading={props.loading}
          onClick={props.onSubmit}
          icon={<UploadOutlined className="mr-0" />}
          type="primary"
        >
          Subir contenido
        </Button>
      )}
    </div>
  );
};

TUploadMultipleFile.displayName = "TUploadMultipleFile";

TUploadMultipleFile.defaultProps = {
  className: "",
};

export default TUploadMultipleFile;
